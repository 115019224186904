var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('a-modal',{attrs:{"centered":"","mask-closable":false,"ok-button-props":{ props: { loading: _vm.submitting } },"title":"设置"},on:{"ok":_vm.handleSubmit},model:{value:(_vm.isShow),callback:function ($$v) {_vm.isShow=$$v},expression:"isShow"}},[_c('a-form',{staticClass:"simiot-compact-form",attrs:{"form":_vm.form,"label-col":{ span: 7 },"wrapper-col":{ span: 15 }},on:{"submit":_vm.handleSubmit}},[_c('a-form-item',{attrs:{"label":"当前信用额度(元)"}},[_vm._v(" "+_vm._s(_vm._f("formatFloat")(_vm.currentCredit))+" ")]),_c('a-form-item',{attrs:{"label":"新信用额度(元)"}},[_c('a-input-number',{directives:[{name:"decorator",rawName:"v-decorator",value:(['credit', {
            rules: [
              { required: true, message: '请输入新信用额度' }
            ]
          }]),expression:"['credit', {\n            rules: [\n              { required: true, message: '请输入新信用额度' }\n            ]\n          }]"}],staticStyle:{"width":"100%"},attrs:{"min":0,"max":10000000,"step":0.01,"precision":2}})],1),_c('a-form-item',{attrs:{"label":"备注"}},[_c('a-textarea',{directives:[{name:"decorator",rawName:"v-decorator",value:(['remark', {
            rules: [
              { max: 200, message: '最多200个字符' } ]
          }]),expression:"['remark', {\n            rules: [\n              { max: 200, message: '最多200个字符' },\n            ]\n          }]"}],attrs:{"auto-size":{ minRows: 3, maxRows: 5 }}})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }